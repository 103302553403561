<template>
  <b-container fluid>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
      <h2>Avize emise</h2>
      <b-button-toolbar class="pb-3">
        <b-dropdown right class="mx-1" v-if="canExportDeliveryNote">
          <template #button-content>
            <i class="fas fa-file-excel mr-1" />
            <span class="d-none d-md-inline">Exportă avize</span>
          </template>
          <b-dropdown-item @click="handleExcelDeliveryNoteExport">
            Exportă ca raport Excel
          </b-dropdown-item>
        </b-dropdown>
      </b-button-toolbar>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm">
          <b-form @submit.stop.prevent="resetPageAndLookup">
            <b-form-group>
              <b-input-group>
                <b-form-input id="queryTerm" v-model="queryTerm" trim placeholder="Caută"></b-form-input>
                <b-input-group-append>
                  <b-button type="submit" variant="primary" :disabled="xhrRequestRunning"><i class="fas fa-search"/><span class="d-none d-lg-inline ml-1">Caută</span></b-button>
                  <b-button @click="clearQueryTerm" variant="secondary" :disabled="xhrRequestRunning"><i class="fas fa-times"/><span class="d-none d-lg-inline ml-1">Curăță</span></b-button>
                </b-input-group-append>
              </b-input-group>
              <date-picker v-model="dateRangeFilter"
                :shortcuts="datePickerShortcuts"
                @input="onDateRangeInput"
                range
                input-class="form-control mt-1"
                class="w-100"
                value-type="YYYY-MM-DD"
                :disabled-date="(date, currentValue) => date > new Date()"
                :clearable="false">
              </date-picker>
            </b-form-group>
          </b-form>
        </div>
        <div class="col-sm">
          <b-button @click="performLookupInternal(false)" :disabled="tableBusy"><i class="fas fa-sync"/><span class="d-none d-lg-inline ml-1">Reîncarcă</span></b-button>
          <div v-if="numPages > 1" class="mt-4">
            <b-pagination-nav :disabled="tableBusy" align="center" v-if="numPages > 1" :link-gen="paginationLinkGen" :number-of-pages="numPages" v-model="currentPage" use-router></b-pagination-nav>
          </div>
        </div>
        <div class="col-sm">
          <multiselect
            v-model="filters.statusCode.val"
            placeholder="Filtre stare"
            selected-label="Selectat"
            select-label="Alege cu Enter"
            deselect-label="Elimină cu Enter"
            :options="statusCodeFilterOptions"
            :allow-empty="true"
            :multiple="true"
            :custom-label="statusCodeFilterOptionsCustomLabel"
            @input="resetPageAndLookup"
            class="status-code-filter">
            <template
              slot="option"
              slot-scope="props"
            >
              {{props.option | displayDeliveryNoteStatus}}
            </template><template
              slot="singleLabel"
              slot-scope="props"
            >
              {{props.option | displayDeliveryNoteStatus}}
            </template>
            <template
              slot="tag"
              slot-scope="props">
              {{props.option | displayDeliveryNoteStatus}}
            </template>
            <template
              slot="clear">
              <transition name="fade">
                <div class="multiselect__clear" v-if="filters.statusCode.val.length" @mousedown.prevent.stop="clearStatusCodeFilter()">
                  <i class="fas fa-times"/>
                </div>
              </transition>
            </template>
          </multiselect>
          <multiselect
            v-model="clientFilterSelection"
            track-by="clientCode"
            placeholder="Filtre client"
            selected-label="Selectat"
            select-label="Alege cu Enter"
            deselect-label="Elimină cu Enter"
            :options="clientFilterOptions"
            :loading="isClientLookupRunning"
            :allow-empty="true"
            :multiple="false"
            :hide-selected="false"
            :custom-label="clientCustomLabel"
            @search-change="asyncLookupClient"
            @input="clientFilterInputHandler"
            class="status-code-filter">
            <template
              slot="option"
              slot-scope="props"
            >
              <b-container>
                <div class="d-flex" align-v="center">
                  <span class="mt-1 mr-2">{{props.option.clientName}}</span>
                  <b-badge variant="secondary" class="badge-lg">
                    <i class="fas fa-briefcase mr-1" />
                    {{props.option.clientCode}}
                  </b-badge>
                </div>
              </b-container>
            </template>
            <template
              slot="noResult"
              slot-scope="props">
              {{ (props.search && props.search.length &lt; 2) ? 'Introdu măcar 2 caractere' : 'N-am găsit niciun rezultat'}}
            </template>
            <template
              slot="singleLabel"
              slot-scope="props"
            >
              <div class="d-flex" align-v="center">
                <span class="mr-2">{{props.option.clientName}}</span>
                <b-badge variant="secondary" class="badge-lg">
                  <i class="fas fa-briefcase mr-1" />
                  {{props.option.clientCode}}
                </b-badge>
              </div>
            </template>
            <template
              slot="clear">
              <transition name="fade">
                <div class="multiselect__clear" v-if="filters.client.val && filters.client.val.length" @mousedown.prevent.stop="clearClientFilter()">
                  <i class="fas fa-times"/>
                </div>
              </transition>
            </template>
          </multiselect>
          <div class="container-fluid">
            <div class="row">
              <div class="col text-center">
                <b-button
                  variant="secondary"
                  :disabled="xhrRequestRunning"
                  @click="clearFilters">
                  <i class="fas fa-times"/>
                  <span class="d-none d-lg-inline ml-1">Curăță</span>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-table
      :show-empty="!tableBusy"
      small
      striped
      hover
      empty-text="Nu este înregistrată nicio comandă"
      :busy.sync="tableBusy"
      :items="items"
      :tbody-tr-class="rowClass"
      :fields="tableFields"
      :sort-by.sync="orderBy"
      :sort-desc.sync="sortDesc"
      :no-local-sorting="true">
      <template v-slot:cell(invoiceNumber)="row">
        {{row.value}}
        <span v-if="row.item.clientOrderCode">
          <br>
          <span>{{row.item.clientOrderCode}}</span>
          <router-link :to="{ name: 'clientOrderDetails', params: { clientOrderId: row.item.clientOrderId } }">
            <i class="fas fa-link ml-1 text-smaller" v-b-tooltip.hover.bottom="'Vezi comanda'"/>
          </router-link>
        </span>
      </template>
      <template v-slot:cell(issuedByFullName)="row">
        <user-name-badge :user-name="row.item.issuedBy" />
      </template>
      <template v-slot:cell(statusCode)="row">
        {{(row.item.isCanceled ? 'CANCELED' : 'ISSUED') | displayDeliveryNoteStatus}}
      </template>
      <template v-slot:cell(invoiceLinesValue)="row">
        {{ row.item.invoiceLinesValue | displayAsDecimal(2) }}
      </template>
      <template v-slot:cell(invoiceLinesVatValue)="row">
        {{ row.item.invoiceLinesVatValue | displayAsDecimal(2) }}
      </template>
      <template v-slot:cell(invoiceLinesValueWithVat)="row">
        {{ row.item.invoiceLinesValue + row.item.invoiceLinesVatValue | displayAsDecimal(2) }}
      </template>
      <template v-slot:cell(clientName)="row">
        <span>
          <span>{{row.value}}</span>
          <a href="#"
            class="ml-1"
            v-if="row.item.clientCode"
            @click="showClientDetails(row.item.clientCode)"
            v-b-tooltip.hover.bottom="'Detalii client'">
            <i class="fas fa-info-circle" />
          </a>
        </span>
        <span v-if="row.item.clientCode">
          <br>
          <b-badge
           variant="secondary"
           class="badge-lg"
           v-b-tooltip.hover.bottom="'Cod client'">
           <i class="fas fa-briefcase mr-1" />
           {{row.item.clientCode}}
          </b-badge>
        </span>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          :to="{name: 'issuedInvoiceDetails', params: { issuedInvoiceId: row.item.issuedInvoiceId  }, query: { isDeliveryNote: true } }"
          v-b-tooltip.hover title="Detalii aviz"
          class="mr-1">
          <i class="fas fa-eye" />
        </b-button>
        <b-button
          size="sm"
          v-b-tooltip.hover title="Descarcă PDF"
          class="mr-1"
          @click="showDownloadPdfModal(row.item)">
          <i class="fas fa-file-invoice-dollar" />
        </b-button>
      </template>
    </b-table>

    <download-invoice-modal
      ref="downloadInvoiceModal"/>

    <b-pagination-nav :disabled="tableBusy" align="center" v-if="numPages > 1" :link-gen="paginationLinkGen" :number-of-pages="numPages" v-model="currentPage" use-router></b-pagination-nav>
    <client-details-modal :usage="'DETAILS'" :initialClientCode="modalInitialClientCode" ref="clientDetailsModal" :callback="clientCreateModalCallback"/>
  </b-container>
</template>

<style lang="scss">

div.multiselect.status-code-filter, div.multiselect.created-by-guid-filter, div.multiselect.has-issued-invoices-filter {
  .multiselect__content-wrapper {
    width: 100%;
  }

  .multiselect__clear {
    position: absolute;
    right: 26px;
    height: 24px;
    width: 24px;
    display: block;
    cursor: pointer;
    z-index: 2;
    padding: 4px 8px;
    color: #999;
  }
}

div.multiselect.created-by-guid-filter {
  .multiselect__single {
    padding-left: 0px;
  }

  .multiselect__tags {
    padding-left: 4px;
    padding-top: 1px;
  }
}

div.multiselect.has-issued-invoices-filter {
  .multiselect__single {
    padding-left: 4px;
  }

  .multiselect__tags {
    padding-left: 4px;
    padding-top: 1px;
  }
}

.mx-datepicker-sidebar {
  width: 130px;
}
.mx-datepicker-sidebar+.mx-datepicker-content {
  margin-left: 130px;
}

div.multiselect.status-code-filter {
  .multiselect__option {
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 4px;
  }

  multiselect__tags {
    span.badge {
      padding-bottom: 0 !important;
    }
  }
}
</style>

<script>
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import BigNumber from 'bignumber.js';
import { mapState, mapActions, mapGetters } from 'vuex';

import ClientDetailsModal from '../components/ClientDetailsModal.vue';
import UserNameBadge from '../components/UserNameBadge.vue';
import DownloadInvoiceModal from '../components/DownloadInvoiceModal.vue';

const { DateTime } = require('luxon');
const qs = require('qs');
const _ = require('lodash');

function deserializeAndMergeFilters(currentFilters, ...serializedFilters) {
  // Cheap clone of our current filters hierarchy using JSON roundtrip
  const duplicatedFilters = JSON.parse(JSON.stringify(currentFilters));

  try {
    // Make one big array of all the serialized filters and then build a filters object out of it
    const deserialized = serializedFilters.reduce((acc, x) => [...acc, ...JSON.parse(x)], [])
      .reduce((acc, x) => ({
        [x.field]: {
          op: x.op,
          val: x.val,
        },
        ...acc,
      }), {});

    // Replace all existing filters with data from the serialied filter arrays
    Object.keys(duplicatedFilters).forEach((key) => {
      if (typeof deserialized[key] !== 'object') {
        return;
      }

      if (typeof deserialized[key].op === 'string' && typeof deserialized[key].val !== 'undefined') {
        duplicatedFilters[key].op = deserialized[key].op;
        duplicatedFilters[key].val = deserialized[key].val;
      }
    });

    return duplicatedFilters;
  } catch (ex) {
    return duplicatedFilters;
  }
}

export default {
  name: 'DeliveryNotes',
  components: {
    UserNameBadge,
    ClientDetailsModal,
    DatePicker,
    DownloadInvoiceModal,
  },
  data() {
    return {
      currentPage: 1,
      numItems: 0,
      numPages: 1,
      perPage: 20,
      orderBy: 'issuingDate',
      sortDesc: true,
      queryTerm: null,
      modalInitialClientCode: null,
      dateRangeFilter: [
        DateTime.fromJSDate(this.getFirstDayOfGivenMonthsAgo(1)).toISODate(),
        DateTime.now().toISODate(),
      ],
      datePickerShortcuts: [
        {
          text: 'Ultimele 2 luni',
          onClick: () => [
            this.getFirstDayOfGivenMonthsAgo(1),
            new Date(),
          ],
        },
        {
          text: 'Ultimele 3 luni',
          onClick: () => [
            this.getFirstDayOfGivenMonthsAgo(2),
            new Date(),
          ],
        },
        {
          text: 'Doar luna curenta',
          onClick: () => [
            this.getFirstDayOfGivenMonthsAgo(0),
            new Date(),
          ],
        },
        {
          text: 'Anul curent',
          onClick: () => [
            new Date(new Date().getFullYear(), 0, 1),
            new Date(),
          ],
        },
        {
          text: 'Ultimii 2 ani',
          onClick: () => [
            new Date(new Date().getFullYear() - 1, 0, 1),
            new Date(),
          ],
        },
        {
          text: 'Ultimii 10 ani',
          onClick: () => [
            new Date(new Date().getFullYear() - 9, 0, 1),
            new Date(),
          ],
        },
      ],
      defaultFilters: {
        statusCode: {
          val: [],
          op: 'IN',
        },
        client: {
          val: [],
          op: '=',
        },
        lowerBoundDate: {
          val: DateTime.fromJSDate(this.getFirstDayOfGivenMonthsAgo(1)).toISODate(),
          op: '>=',
        },
        upperBoundDate: {
          val: DateTime.now().toISODate(),
          op: '<=',
        },
      },
      filters: {
        statusCode: {
          val: [],
          op: 'IN',
        },
        client: {
          val: null,
          op: '=',
        },
        lowerBoundDate: {
          val: DateTime.fromJSDate(this.getFirstDayOfGivenMonthsAgo(1)).toISODate(),
          op: '>=',
        },
        upperBoundDate: {
          val: DateTime.now().toISODate(),
          op: '<=',
        },
      },
      tableFields: [
        {
          key: 'invoiceNumber',
          label: 'Număr aviz / Comandă',
          sortable: true,
        },
        {
          key: 'issuingDate',
          label: 'Data emitere',
          formatter(val) {
            return DateTime.fromISO(val).toLocaleString(DateTime.DATE_SHORT);
          },
          sortable: true,
          orderByDirection: 'desc',
        },
        {
          key: 'statusCode',
          label: 'Stare',
        },
        {
          key: 'invoiceLinesValue',
          label: 'Val. f. TVA',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'invoiceLinesVatValue',
          label: 'Val. TVA',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'invoiceLinesValueWithVat',
          label: 'Val. cu TVA',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'clientName',
          label: 'Client',
          sortable: true,
        },
        {
          key: 'clientDeliveryAddressName',
          label: 'Adr. livrare',
          sortable: true,
        },
        {
          key: 'issuedByFullName',
          label: 'Emis de',
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      items: [],
      tableBusy: false,
      statusCodeFilterOptions: [
        'ISSUED',
        'CANCELED',
      ],

      clientFilterOptions: [],
      clientFilterSelection: null,
      isClientLookupRunning: false,

      performingLookup: false,
    };
  },
  watch: {
    orderBy() {
      this.resetPageAndLookup();
    },
    sortDesc() {
      this.resetPageAndLookup();
    },
  },
  computed: {
    itemsAvailable() { return this.items.length; },
    ...mapState(['xhrRequestRunning', 'userGuid']),
    ...mapGetters(['canExportDeliveryNote']),
  },
  filters: {
    displayAsDate(value) {
      return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
    },
    displayAsDecimal(value, decimals = 2) {
      return `${new BigNumber(value).toFormat(decimals)}`;
    },
    displayDeliveryNoteStatus(value) {
      if (value === 'ISSUED') return 'emis';
      if (value === 'CANCELED') return 'anulată';
      return value;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.currentPage = to.query.page || 1;
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.perPage = to.query.perPage || 20;
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.orderBy = to.query.orderBy || 'issuingDate';
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.sortDesc = !to.query.orderByDirection || to.query.orderByDirection === 'd';
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.queryTerm = to.query.queryTerm || null;
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.filters = deserializeAndMergeFilters(
        vm.defaultFilters,
        // The received filters override any saved filters
        to.query.filters || '',
      );
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.dateRangeFilter = vm.populateDateRangeFilter(vm.dateRangeFilter, vm.filters);
      // We don't want to save the filters as we've just loaded some
      vm.performLookupInternal(false);
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.currentPage = to.query.page || 1;
    this.perPage = to.query.perPage || this.perPage || 20;
    this.orderBy = to.query.orderBy || 'issuingDate';
    this.sortDesc = !to.query.orderByDirection || to.query.orderByDirection === 'd';
    this.queryTerm = to.query.queryTerm || null;
    this.filters = deserializeAndMergeFilters(
      this.defaultFilters,
      to.query.filters || JSON.stringify(this.defaultFilters || {}) || '',
    );
    this.dateRangeFilter = this.populateDateRangeFilter(this.dateRangeFilter, this.filters);
    try {
      await this.performLookupInternal();
    } finally {
      next();
    }
  },
  created() {
    // Debounce the asunc lookup so we don't swamp the server
    this.asyncLookupClient = _.debounce(this.asyncLookupClient, 450);
  },
  methods: {
    statusCodeFilterOptionsCustomLabel(label) {
      if (label === 'ISSUED') return 'emisă';
      if (label === 'CANCELED') return 'anulată';
      return label;
    },

    clientCustomLabel(label) {
      if (label && label.clientCode) {
        return `${label.clientName} - ${label.clientCode}`;
      }

      return null;
    },

    populateDateRangeFilter(dateRangeFilter, filters) {
      if ((filters.lowerBoundDate.val && filters.upperBoundDate.val)) {
        return [
          filters.lowerBoundDate.val,
          filters.upperBoundDate.val,
        ];
      }
      return dateRangeFilter;
    },

    onDateRangeInput(newInput) {
      [this.filters.lowerBoundDate.val, this.filters.upperBoundDate.val] = newInput;
      this.resetPageAndLookup();
    },

    getFirstDayOfGivenMonthsAgo(monthsAgo) {
      const date = new Date();
      date.setDate(1);
      date.setMonth(date.getMonth() - monthsAgo);
      return date;
    },

    ...mapActions([
      'performDeliveryNotesQuery',
      'performClientLookup',
    ]),

    showClientDetails(clientCode) {
      this.modalInitialClientCode = clientCode;
      this.$nextTick(() => this.$refs.clientDetailsModal.showModal());
    },

    async asyncUpdateClientFilterOptions(query) {
      if (query.length >= 2) {
        this.clientFilterOptions = await this.performClientLookup({ lookupTerm: query });
      }
    },

    async asyncLookupClient(query) {
      try {
        this.isClientLookupRunning = true;
        await this.asyncUpdateClientFilterOptions(query);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      } finally {
        this.isClientLookupRunning = false;
      }
    },

    clearClientFilter() {
      this.clientFilterInputHandler(null);
    },

    clientFilterInputHandler(value) {
      // Cancel any further lookups as we have selected something already
      this.asyncLookupClient.cancel();

      this.clientFilterSelection = value;
      const isArray = Array.isArray(value);
      this.filters.client.val = isArray
        ? (value || []).map((v) => v.clientCode)
        : ((value || {}).clientCode);

      if (!this.performingLookup) {
        this.resetPageAndLookup();
      }
    },

    serializeFilters() {
      return JSON.stringify(Object.keys(this.filters).map((key) => ({
        field: key,
        op: this.filters[key].op,
        val: this.filters[key].val,
      })).filter((filter) => typeof filter.val !== 'undefined' && filter.val !== null && (!Array.isArray(filter.val) || filter.val.length > 0)));
    },

    buildQueryObject(page) {
      return {
        page,
        perPage: this.perPage,
        orderBy: this.orderBy,
        orderByDirection: this.sortDesc ? 'd' : 'a',
        queryTerm: this.queryTerm ? this.queryTerm : undefined,
        filters: this.serializeFilters(),
      };
    },

    paginationLinkGen(page) {
      return {
        name: 'deliveryNote',
        query: this.buildQueryObject(page),
      };
    },

    rowClass(/* item, type */) {
    },

    clearFilters() {
      this.filters = this.defaultFilters;
      this.resetPageAndLookup();
    },

    clearStatusCodeFilter() {
      this.filters.statusCode.val = [];
      this.resetPageAndLookup();
    },

    clearQueryTerm() {
      this.queryTerm = null;
      this.resetPageAndLookup();
    },

    resetPageAndLookup() {
      this.currentPage = 1;

      this.performLookup();
    },

    clientCreateModalCallback() {
      this.$refs.clientDetailsModal.hideModal();
    },

    async performLookup() {
      try {
        this.$router.push(this.paginationLinkGen(this.page));
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.error(ex);
      }
    },

    async handleExcelDeliveryNoteExport() {
      const serializedFilters = this.serializeFilters();

      // We also need to make sure that we can populate the "client" filter if we aleady have a value
      // and it's not within the values that we have cached
      if (this.filters.client.val) {
        if (!this.clientFilterOptions.find((x) => x.clientCode === this.filters.client.val)) {
          await this.asyncUpdateClientFilterOptions(this.filters.client.val);
          this.clientFilterSelection = this.clientFilterOptions.find((x) => x.clientCode === this.filters.client.val);
        }
      } else {
        this.clientFilterSelection = null;
      }

      const params = {
        orderBy: this.orderBy,
        orderByDirection: this.sortDesc ? 'd' : 'a',
        queryTerm: this.queryTerm ? this.queryTerm : undefined,
        filters: serializedFilters,
      };

      window.location.href = `/api/delivery-note/excel-export/list?${qs.stringify(params)}`;
    },

    async performLookupInternal() {
      this.tableBusy = true;
      this.performingLookup = true;

      const serializedFilters = this.serializeFilters();

      // We also need to make sure that we can populate the "client" filter if we aleady have a value
      // and it's not within the values that we have cached
      if (this.filters.client.val) {
        if (!this.clientFilterOptions.find((x) => x.clientCode === this.filters.client.val)) {
          await this.asyncUpdateClientFilterOptions(this.filters.client.val);
          this.clientFilterSelection = this.clientFilterOptions.find((x) => x.clientCode === this.filters.client.val);
        }
      } else {
        this.clientFilterSelection = [];
      }

      let result;
      try {
        result = await this.performDeliveryNotesQuery({
          page: this.currentPage,
          perPage: this.perPage,
          orderBy: this.orderBy,
          orderByDirection: this.sortDesc ? 'd' : 'a',
          queryTerm: this.queryTerm ? this.queryTerm : undefined,
          filters: serializedFilters,
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);

        result = {
          items: [],
          count: 0,
          limit: this.perPage,
          pages: 0,
        };
      }

      this.items = result.items;
      this.numItems = result.count;
      this.perPage = result.limit;
      // Avoid a validation error on the number of pages if we get back zero pages (no results)
      this.numPages = result.pages || 1;

      this.tableBusy = false;
      this.performingLookup = false;
    },

    showDownloadPdfModal(item) {
      this.$nextTick(() => this.$refs.downloadInvoiceModal.showModal({ issuingYear: item.issuingYear, invoiceIndex: item.invoiceIndex }, 'AVE'));
    },
  },
};
</script>
