<template>
  <b-container fluid>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
      <h2>{{ pageTitle }}</h2>
      <b-button-toolbar class="pb-3"
        v-if="issuedInvoiceDetails">
        <b-button class="mx-1 p-2" id="navigateBackToolbarButton" :to="lastRoute || { name: 'issuedInvoice' }"><i class="fas fa-caret-left"/><span class="d-none d-md-inline ml-1">Înapoi</span></b-button>

        <b-button
          id="downloadPdfToolbarButton"
          class="mx-1 p-2"
          @click="onExportInvoice()">
          <i class="fas fa-file-pdf mr-1"/>Descarcă PDF
        </b-button>

        <b-button right class="mx-1" v-if="!issuedInvoiceDetails.isCancelled && issuedInvoiceDetails.isExported && !isDeliveryNote" @click="onExportDispatchNote()">
          <i class="fas fa-file-invoice mr-1" />Descarcă aviz
        </b-button>

        <b-dropdown class="mx-1" size="sm" v-if="!issuedInvoiceDetails.isCanceled && issuedInvoiceDetails.isExported">
          <template v-slot:button-content>
            <font-awesome-icon :icon="['fas', 'file-certificate']" class="mr-1"/>Decl. conform.
          </template>
          <b-dropdown-item :href="'/api/client-order/declaration-of-conformity/' + issuedInvoiceDetails.documentType + '/' + issuedInvoiceDetails.issuingYear + '/' + issuedInvoiceDetails.invoiceIndex">PDF</b-dropdown-item>
          <b-dropdown-item :href="'/api/client-order/declaration-of-conformity/' + issuedInvoiceDetails.documentType + '/' + issuedInvoiceDetails.issuingYear + '/' + issuedInvoiceDetails.invoiceIndex + '?fileFormat=xlsx'">Excel</b-dropdown-item>
          <b-dropdown-item :href="'/api/client-order/declaration-of-conformity/' + issuedInvoiceDetails.documentType + '/' + issuedInvoiceDetails.issuingYear + '/' + issuedInvoiceDetails.invoiceIndex + '?fileFormat=docx'">Word</b-dropdown-item>
        </b-dropdown>
        <b-dropdown right class="mx-1" v-if="canAdminister && issuedInvoiceDetails.clientOrderStatusCode === 'PREPARED'">
          <template v-slot:button-content><i class="fas fa-user-ninja mx-1"/>Admin</template>
          <b-dropdown-item-button @click="cancelInvoice(issuedInvoiceDetails)" v-if="!issuedInvoiceDetails.isCancelled">{{ cancelInvoiceText }}</b-dropdown-item-button>
          <b-dropdown-item-button @click="revertInvoiceCancellation(issuedInvoiceDetails)" v-if="issuedInvoiceDetails.isCancelled">{{ reinstateInvoiceText }}</b-dropdown-item-button>
        </b-dropdown>
        <b-button class="mx-1 p-2" @click="showSendInvoiceEmailModal()">
          <i class="fas fa-envelope mr-1" /><span>Trimite email</span>
        </b-button>
      </b-button-toolbar>
    </div>

    <!-- Invoice details card -->
    <b-card v-if="issuedInvoiceDetails">
      <div slot="header">
        {{ invoiceDetailsText }} <strong>{{ issuedInvoiceDetails.invoiceSeries }} {{ issuedInvoiceDetails.invoiceIndex }}</strong>&nbsp; din <strong>{{ issuedInvoiceDetails.issuingDate | displayAsDate }}</strong> {{ isDeliveryNote ? 'emis' : 'emisă' }} de <user-name-badge :user-name="issuedInvoiceDetails.issuedBy" />
        <b-button class="mx-1" size="sm" @click="showEventLogModal">
          <i class="fas fa-history mr-1" /><span>Istoric</span>
        </b-button>
      </div>
      <div class="row">
        <div class="col-md-6 col-12">
          <label class="col-12">Client</label>
          <blockquote class="blockquote">
            <strong>{{ issuedInvoiceDetails.clientName }}</strong> <br>
            <span>{{ issuedInvoiceDetails.clientCode }}</span> <br>
            <span>{{ issuedInvoiceDetails.clientCommerceRegistryNumber }}</span> <br>
            <span class="preserve-newlines">{{ clientDetailsInvoiceFullAddress }}</span>
          </blockquote>
        </div>
        <div class="col-md-6 col-12">
          <label class="col-12">Adresă livrare</label>
          <blockquote v-if="issuedInvoiceDetails.clientDeliveryAddressName" class="col-12 blockquote">
            <strong>{{issuedInvoiceDetails.clientDeliveryAddressName}}</strong><br>
            <span v-if="issuedInvoiceDetails.clientDeliveryAddressPartnerInternalCode">Cod: {{issuedInvoiceDetails.clientDeliveryAddressPartnerInternalCode}}</span><br>
            <span class="preserve-newlines">{{ invoiceDeliveryFullAddress }}</span >
          </blockquote>
          <blockquote v-else>-</blockquote>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-6">
          <label class="col-12">Scadența</label>
          <blockquote class="blockquote">{{issuedInvoiceDetails.paymentDueDate | displayAsDate}}</blockquote>
        </div>
        <div class="col-6">
          <label class="col-12">Observații factură</label>
          <blockquote class="blockquote" v-if="!!issuedInvoiceDetails.invoiceNotes">{{issuedInvoiceDetails.invoiceNotes}}</blockquote>
          <blockquote class="blockquote" v-else>Nu există observații pentru această factură</blockquote>
        </div>
      </div>
      <br>
      <div class="row">
        <div class="col-6">
          <div v-if="issuedInvoiceDetails.clientOrderCode">
            <label class="col-12">Comanda</label>
            <blockquote class="blockquote">
              <span>{{issuedInvoiceDetails.clientOrderCode}}</span>
              <router-link :to="{ name: 'clientOrderDetailsByCode', params: { clientOrderCode: issuedInvoiceDetails.clientOrderCode } }">
                <i class="fas fa-link ml-1 text-smaller" v-b-tooltip.hover.bottom="'Vezi comanda'"/>
              </router-link>
            </blockquote>
          </div>
        </div>
        <div class="col-6" v-if="issuedInvoiceDetails.salesAgentName">
          <label class="col-12">Agent vânzări</label>
          <user-name-badge :user-name="issuedInvoiceDetails.salesAgentName"/>
        </div>
      </div>
    </b-card>
    <br>
    <!-- Invoice sent emails card -->
    <b-card v-if="sentEmails && sentEmails.length">
      <div slot="header">
        Istoric document
      </div>
      <div class="ml-2" v-for="email in sentEmails" :key="email.emailFacturaEmisaId">
        <strong>{{ email.sentAt | displayAsDateTime }}</strong> <user-name-badge :user-name="email.creatDe" />&nbsp;a trimis documentul pe email la&nbsp;
        <strong> {{ email.recipient }}</strong>
      </div>
    </b-card>
    <br>

    <b-table
    :show-empty="!tableBusy"
    small
    striped
    hover
    :empty-text="noPositionsText"
    :busy.sync="tableBusy"
    :items="items"
    :foot-clone="true"
    :fields="tableFields">
      <template v-slot:cell(numarPozitie)="row">
        {{ row.value }}.
      </template>
      <template v-slot:cell(codProdus)="row">
        <span>
          <b-badge
            variant="info"
            v-if="row.value"
            class="badge-lg">
            <i class="fas fa-tag mr-1" />
            <samp>{{ row.value }}</samp>
          </b-badge>

          <b-button
            variant="link"
            size="sm"
            class="ml-1"
            v-if="row.value"
            v-b-tooltip.hover="'Copiază codul de produs'"
            v-clipboard:success="clipboardSuccessHandler"
            v-clipboard:error="clipboardErrorHandler"
            v-clipboard="() => row.value"><i class="far fa-copy" />
          </b-button>

          <b-badge
            v-if="row.item.esteServiciu"
            variant="primary"
            class="badge-lg ml-1">
            <i class="fas fa-briefcase" />
            Serviciu
          </b-badge>

          <b-badge variant="secondary" class="badge-lg ml-1" v-if="row.item.catalogItemBarCode">
            <i class="fas fa-barcode mr-1"/>
            <samp>{{row.item.codBare}}</samp>
          </b-badge>
        </span>
        <br v-if="row.value">
        <span>{{row.item.denumireProdus}}</span>
        <br v-if="row.item.numarLot || row.item.dataExpirareLot">
        <span v-if="row.item.numarLot">Lot {{ row.item.numarLot }}&nbsp;</span>
        <span v-if="row.item.dataExpirareLot" class="ml-3">Exp. {{ row.item.dataExpirareLot | displayAsDate }}</span>
      </template>
      <template v-slot:cell(cantitate)="row">
        {{ dp(row.item.cantitate, decimalPlacesQty) }}
      </template>
      <template v-slot:cell(pretVanzare)="row">
        {{ dp(row.item.pretVanzare, decimalPlacesPrice) }}
      </template>
      <template v-slot:cell(valoareVanzare)="row">
        {{ dp(row.item.valoareVanzare, 2) }}
      </template>
      <template v-slot:cell(procentTVA)="row">
        {{ row.item.procentTVA | displayAsPercentage }}
      </template>
      <template v-slot:cell(valoareTVA)="row">
        {{ dp(row.item.valoareTVA, 2) }}
      </template>
      <template v-slot:cell(valoareDiscount)="row">
        - {{ dp(row.item.valoareDiscount, 2) }}
      </template>
      <template v-slot:cell(valoareTVADiscount)="row">
        - {{ dp(row.item.valoareTVADiscount, 2) }}
      </template>
      <template v-slot:cell(valoareInclusivTVA)="row">
        {{ toBigNumber(row.item.valoareVanzare).plus(row.item.valoareTVA).minus(row.item.valoareDiscount).minus(row.item.valoareTVADiscount).toFormat(2) }}
      </template>

      <template v-slot:foot()>&nbsp;</template>
      <template v-slot:foot(codProdus)>
        <span v-if="distinctVatPercentages > 1">
          <br v-for="n in distinctVatPercentages" :key="n"/>
        </span>
        TOTAL
      </template>
      <template v-slot:foot(cantitate)>
        <span v-if="distinctVatPercentages > 1">
          <br v-for="n in distinctVatPercentages" :key="n"/>
        </span>
        {{ items.filter((i) => (i.esteServiciu === false)).reduce((s, i) => s.plus(i.cantitate), toBigNumber(0)).toFormat(decimalPlacesQty) }}
      </template>
      <template v-slot:foot(valoareVanzare)>
        <span v-for="val in totalsRowValue" :key="val.toString()" class="d-block">
          {{ val.toFormat(2) }}
        </span>
      </template>
      <template v-slot:foot(procentTVA) v-if="totalsRowVatPercentages.length > 1">
        <span class="d-block" v-for="percentage in totalsRowVatPercentages" :key="percentage">
          {{ percentage}}
        </span>
      </template>
      <template v-slot:foot(valoareTVA)>
        <span v-for="val in totalsVatValue" :key="val.toString()" class="d-block">
          {{ val.toFormat(2) }}
        </span>
      </template>
      <template v-slot:foot(valoareDiscount)>
        <span v-for="val in totalsDiscountValue" :key="val.toString()" class="d-block">
          - {{ val.toFormat(2) }}
        </span>
      </template>
      <template v-slot:foot(valoareTVADiscount)>
        <span v-for="val in totalsVatDiscountValue" :key="val.toString()" class="d-block">
          - {{ val.toFormat(2) }}
        </span>
      </template>
      <template v-slot:foot(valoareInclusivTVA)>
        <span v-for="val in totalsValueIncludingVat" :key="val.toString()" class="d-block">
          {{ val.toFormat(2) }}
        </span>
      </template>
    </b-table>

    <event-log-modal
      ref="eventLogModal"/>

    <download-invoice-modal
      ref="downloadInvoiceModal"
      @downloadStarted="onInvoiceDownloadStarted"/>

    <send-invoice-email-modal
      ref="sendInvoiceEmailModal"
      @issuedInvoiceEmailSent="sendInvoiceCallback"/>

  </b-container>
</template>

<style lang="scss">
.col-max-w-50px {
  max-width: 50px;
}
</style>

<script>
import BigNumber from 'bignumber.js';
import { mapState, mapGetters, mapActions } from 'vuex';
import toasts from '@/services/toasts';
import UserNameBadge from '../components/UserNameBadge.vue';

import SendInvoiceEmailModal from '../components/SendInvoiceEmailModal.vue';
import EventLogModal from '../components/EventLogModal.vue';
import DownloadInvoiceModal from '../components/DownloadInvoiceModal.vue';

import { EventLogSubjectType } from '../constants';

const { DateTime } = require('luxon');
const _ = require('lodash');

export default {
  name: 'IssuedInvoiceDetails',
  components: {
    UserNameBadge,
    SendInvoiceEmailModal,
    DownloadInvoiceModal,
    EventLogModal,
  },
  data() {
    return {
      issuedInvoiceId: null,
      issuedInvoiceDetails: null,
      tableBusy: false,
      isDeliveryNote: false,
      decimalPlacesQty: 3,
      decimalPlacesPrice: 5,
      items: [],
      sentEmails: [],
      tableFields: [
        {
          key: 'numarPozitie',
          label: 'Nr. crt.',
          class: 'col-max-w-50px',
        },
        {
          key: 'codProdus',
          label: 'Produs sau serviciu',
          class: 'text-left',
        },
        {
          key: 'cantitate',
          label: 'Cantitate',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'pretVanzare',
          label: 'Preț',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'valoareVanzare',
          label: 'Valoare',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'procentTVA',
          label: 'Cota TVA',
        },
        {
          key: 'valoareTVA',
          label: 'Valoare TVA',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'valoareDiscount',
          label: 'Val. Discount',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'valoareTVADiscount',
          label: 'TVA Discount',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
        {
          key: 'valoareInclusivTVA',
          label: 'Val. inclusiv TVA',
          thClass: 'text-right',
          tdClass: 'text-right',
        },
      ],
    };
  },
  filters: {
    displayAsDate(value) {
      return DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT);
    },
    displayAsDateTime(value) {
      return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_SHORT);
    },
    displayAsPercentage(value, decimals = 0) {
      return `${new BigNumber(value).times(100).decimalPlaces(decimals).toString()} %`;
    },
  },
  computed: {
    ...mapState(['xhrRequestRunning', 'lastRoute']),
    ...mapGetters(['canAdminister']),

    clientDetailsInvoiceFullAddress() {
      let fullAddressParts = '';
      if (this.issuedInvoiceDetails) {
        const streetName = this.issuedInvoiceDetails.clientInvoiceDeliveryStreetName;
        const cityName = this.issuedInvoiceDetails.clientInvoiceDeliveryCityName;
        const countryName = this.issuedInvoiceDetails.clientInvoiceDeliveryCountryName;
        const postalCode = this.issuedInvoiceDetails.clientInvoiceDeliveryPostalCode;
        const countyName = this.issuedInvoiceDetails.clientInvoiceDeliveryCountyName;

        if (streetName) { fullAddressParts += streetName; fullAddressParts += cityName ? '\n' : ''; }
        if (cityName) { fullAddressParts += cityName; fullAddressParts += countyName ? ', ' : '\n'; }
        if (countyName) { fullAddressParts += countyName; fullAddressParts += '\n'; }
        if (postalCode) { fullAddressParts += postalCode; fullAddressParts += countryName ? ', ' : ''; }
        if (countryName) { fullAddressParts += countryName; }
      }
      return fullAddressParts;
    },

    invoiceDeliveryFullAddress() {
      let fullAddressParts = '';
      if (this.issuedInvoiceDetails) {
        const streetName = this.issuedInvoiceDetails.clientDeliveryAddressStreetName;
        const cityName = this.issuedInvoiceDetails.clientDeliveryAddressCityName;
        const countryName = this.issuedInvoiceDetails.clientDeliveryAddressCountryName;
        const postalCode = this.issuedInvoiceDetails.clientDeliveryAddressPostalCode;
        const countyName = this.issuedInvoiceDetails.clientDeliveryAddressCountyName;

        if (streetName) { fullAddressParts += streetName; fullAddressParts += cityName ? '\n' : ''; }
        if (cityName) { fullAddressParts += cityName; fullAddressParts += countyName ? ', ' : '\n'; }
        if (countyName) { fullAddressParts += countyName; fullAddressParts += '\n'; }
        if (postalCode) { fullAddressParts += postalCode; fullAddressParts += countryName ? ', ' : ''; }
        if (countryName) { fullAddressParts += countryName; }
      }
      return fullAddressParts;
    },

    distinctVatPercentages() {
      if (!this.items || !this.items.length) {
        return 0;
      }

      return _.uniq(this.items.map((item) => item.procentTVA)).length;
    },

    totalsRowValue() {
      return this.totalsGroupedByVatPercentage((item) => item.valoareVanzare);
    },

    totalsVatValue() {
      return this.totalsGroupedByVatPercentage((item) => item.valoareTVA);
    },

    totalsDiscountValue() {
      return this.totalsGroupedByVatPercentage((item) => item.valoareDiscount);
    },

    totalsVatDiscountValue() {
      return this.totalsGroupedByVatPercentage((item) => item.valoareTVADiscount);
    },

    totalsValueIncludingVat() {
      return this.totalsGroupedByVatPercentage((item) => new BigNumber(item.valoareVanzare)
        .plus(item.valoareTVA)
        .minus(item.valoareDiscount)
        .minus(item.valoareTVADiscount));
    },

    totalsRowVatPercentages() {
      if (!this.items || !this.items.length) {
        return [];
      }

      const percentages = _.reverse(
        _.sortBy(
          _.uniq(this.items.map((item) => item.procentTVA)), (item) => item,
        ),
      ).map(
        (value) => `${new BigNumber(value).times(100).decimalPlaces(0).toString()} %`,
      );

      return percentages;
    },

    pageTitle() {
      return this.isDeliveryNote ? 'Vizualizare aviz' : 'Vizualizare factură';
    },

    cancelInvoiceText() {
      return this.isDeliveryNote ? 'anulează avizul' : 'anulează factura';
    },

    reinstateInvoiceText() {
      return this.isDeliveryNote ? 'de-anulează avizul' : 'de-anulează factura';
    },

    invoiceDetailsText() {
      return this.isDeliveryNote ? 'Detalii aviz' : 'Detalii factură';
    },

    noPositionsText() {
      return this.isDeliveryNote ? 'Avizul nu are poziții' : 'Factura nu are poziții';
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign
      vm.isLoaded = false;
      // eslint-disable-next-line no-param-reassign
      vm.issuedInvoiceId = String(to.params.issuedInvoiceId);
      // eslint-disable-next-line no-param-reassign
      vm.isDeliveryNote = to.query.isDeliveryNote;
      vm.loadInitialData();
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.isLoaded = false;
    this.issuedInvoiceId = to.params.issuedInvoiceId;
    this.isDeliveryNote = to.query.isDeliveryNote || false;
    try {
      await this.loadInitialData();
    } finally {
      next();
    }
  },
  created() {
    this.loadInitialData();
  },
  methods: {
    ...mapActions([
      'performIssuedInvoiceDetailsQuery',
      'performCancelInvoice',
      'performRevertInvoiceCancellation',
      'performIssuedInvoiceSentEmailsQuery',
    ]),

    totalsGroupedByVatPercentage(selFn) {
      if (!this.items || !this.items.length) {
        return [];
      }

      const groupedByPercentage = _.groupBy(this.items, (item) => item.procentTVA);
      const sortedPercentages = _.reverse(_.sortBy(Object.keys(groupedByPercentage), (item) => item));

      const returnValue = sortedPercentages.map((p) => groupedByPercentage[p].reduce((acc, item) => acc.plus(selFn(item)), new BigNumber(0)));
      if (returnValue.length === 1) {
        return returnValue;
      }

      returnValue.push(this.items.reduce((acc, item) => acc.plus(selFn(item)), new BigNumber(0)));

      return returnValue;
    },

    clipboardSuccessHandler() {
      toasts.success('Textul a fost copiat');
    },

    clipboardErrorHandler() {
      toasts.error('Textul nu a putut fi copiat');
    },

    toBigNumber(value) {
      return new BigNumber(value);
    },

    dp(value, dp) {
      return new BigNumber(value).toFormat(dp === undefined ? 2 : dp);
    },

    async sendInvoiceCallback() {
      let result;
      try {
        result = await Promise.all([
          this.performIssuedInvoiceSentEmailsQuery({ issuedInvoiceId: this.issuedInvoiceId }),
        ]);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        this.errorState = true;

        result = [];
      }

      ([this.sentEmails] = result);
    },

    removeDiscountColumns() {
      const tvaDicountItems = this.items.find((item) => item.valoareTVADiscount !== 0);
      const discountItems = this.items.find((item) => item.valoareDiscount !== 0);

      if (!tvaDicountItems && !discountItems) {
        this.tableFields = this.tableFields.filter(
          (field) => field.key !== 'valoareTVADiscount' && field.key !== 'valoareDiscount',
        );
      }
    },

    async showSendInvoiceEmailModal() {
      this.$refs.sendInvoiceEmailModal.showModal(this.issuedInvoiceDetails.clientCode,
        this.isDeliveryNote ? 'AVZ' : 'FAC',
        this.issuedInvoiceDetails.invoiceIndex,
        this.issuedInvoiceDetails.issuingYear);
    },

    async showEventLogModal() {
      this.$nextTick(() => this.$refs.eventLogModal.showModal(this.issuedInvoiceId, EventLogSubjectType.Invoice));
    },

    async cancelInvoice(invoice) {
      if (!(await this.$bvModal.msgBoxConfirm(this.isDeliveryNote ? 'Confirmi anularea avizului?' : 'Confirmi anularea facturii?', {
        okTitle: 'Da',
        cancelTitle: 'Nu',
      }).catch(() => false))) {
        return;
      }

      const outcome = await this.performCancelInvoice(invoice.issuedInvoiceId);

      if (outcome) {
        toasts.success(this.isDeliveryNote ? 'Avizul a fost anulat' : 'Factura a fost anulată');
      } else {
        toasts.error(this.isDeliveryNote ? 'Avizul nu a putut fi anulat' : 'Factura nu a putut fi anulată');
      }

      this.loadInitialData();
    },

    async revertInvoiceCancellation(invoice) {
      if (!(await this.$bvModal.msgBoxConfirm(this.isDeliveryNote ? 'Confirmi de-anularea avizului?' : 'Confirmi de-anularea facturii?', {
        okTitle: 'Da',
        cancelTitle: 'Nu',
      }).catch(() => false))) {
        return;
      }

      const outcome = await this.performRevertInvoiceCancellation(invoice.issuedInvoiceId);

      if (outcome) {
        toasts.success(this.isDeliveryNote ? 'Avizul a fost de-anulat' : 'Factura a fost de-anulată');
      } else {
        toasts.error(this.isDeliveryNote ? 'Avizul nu a putut fi de-anulat' : 'Factura nu a putut fi de-anulată');
      }

      this.loadInitialData();
    },

    getItemsGroupedByProcentTVA() {
      const groupedValues = _.groupBy(this.items, (item) => item.procentTVA);
      return groupedValues;
    },

    getValoareTVAFooterText() {
      const groupedItems = this.getItemsGroupedByProcentTVA();
      let displayedText = '';

      Object.keys(groupedItems).forEach((key) => {
        displayedText += new BigNumber(key).times(100).decimalPlaces(0).toString()
          .concat('%: ');
        displayedText += groupedItems[key].reduce((s, i) => s.plus(i.valoareTVA), new BigNumber(0)).dp(2).toString();
        displayedText += '\n';
      });

      return displayedText;
    },

    issuedInvoiceHasMoreTVAValues() {
      return Object.keys(this.getItemsGroupedByProcentTVA()).length > 1;
    },

    onExportInvoice() {
      this.$nextTick(() => this.$refs.downloadInvoiceModal.showModal({
        issuingYear: this.issuedInvoiceDetails.issuingYear,
        invoiceIndex: this.issuedInvoiceDetails.invoiceIndex,
        context: true,
      }, this.isDeliveryNote ? 'AVE' : 'FAC'));
    },

    onExportDispatchNote() {
      this.$nextTick(() => this.$refs.downloadInvoiceModal.showModal({
        issuingYear: this.issuedInvoiceDetails.issuingYear,
        invoiceIndex: this.issuedInvoiceDetails.invoiceIndex,
        context: false,
      }, 'AVX'));
    },

    onInvoiceDownloadStarted(payload) {
      if (payload.context) {
        this.issuedInvoiceDetails.isExported = true;
      }
    },

    async loadInitialData() {
      if (!this.issuedInvoiceId) {
        return;
      }

      let result;
      this.errorState = false;
      this.tableBusy = true;
      try {
        result = await Promise.all([
          this.performIssuedInvoiceDetailsQuery({ issuedInvoiceId: this.issuedInvoiceId }),
        ]);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        this.errorState = true;

        result = [];
      }

      this.issuedInvoiceDetails = result.length && result[0].details;
      this.items = result.length && result[0].lines;
      this.sentEmails = result.length && result[0].sentEmails;

      this.decimalPlacesQty = 0;
      this.decimalPlacesPrice = 2;

      this.items.forEach((item) => {
        const dpQty = new BigNumber(item.cantitate).minus(new BigNumber(item.cantitate).integerValue(BigNumber.ROUND_FLOOR)).absoluteValue().dp();
        if (dpQty > this.decimalPlacesQty) {
          this.decimalPlacesQty = dpQty;
        }

        const dpPrice = new BigNumber(item.cantitate).minus(new BigNumber(item.pretVanzare).integerValue(BigNumber.ROUND_FLOOR)).absoluteValue().dp();
        if (dpPrice > this.decimalPlacesPrice) {
          this.decimalPlacesPrice = dpPrice;
        }

        console.log(item, dpQty, dpPrice);
      });

      console.log(this.decimalPlacesQty, this.decimalPlacesPrice);

      if (this.errorState) {
        return;
      }

      if (result) {
        this.isLoaded = true;
        this.tableBusy = false;
        this.removeDiscountColumns();
      }
    },
  },
};
</script>
